// <!-- API -->
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

// <!-- COMPONENTS -->
import NARAStandardTableIcons from '~NARAStandards/components/NARAStandardTableIcons.vue';
// <!-- UTILITIES -->
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';
import { formatISO } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { formatTemperature, formatPercent } from '@/utils/formatters';
import { Temperature, Unit } from '@/utils/enums';

// <!-- COMPOSABLES -->
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useNARAStandardIndex } from '@/hooks/cache/useNARAStandardIndex';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/models/standards/NARAStandard').NARAStandardResource} NARAStandardResource */

import {
    createNARAStandard,
    deleteNARAStandardById,
    updateNARAStandardById,
} from '@/api/standards';

// <!-- COMPOSABLE -->
/**
 * Provides access to all composable submodules.
 */
class NARAStandardManager {
    /**
     * Instantiate a new NARAStandardManager composable.
     * @param {Object} [props] Props to pass to the NARAStandardManager.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [props.grid] AgGrid composable.
     * @param {ReturnType<useNARAStandardIndex>} [props.standards] NARAStandard index composable.
     */
    constructor(props = {}) {
        // Deconstruct parameters.
        const { store, alerts, grid, standards } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();

        /** @type {ReturnType<useNARAStandardIndex>} */
        this.standards = standards ?? useNARAStandardIndex();

        /** @type {NARAStandardManagerConstants} */
        this.constants = new NARAStandardManagerConstants(this);

        /** @type {NARAStandardManagerState} */
        this.state = new NARAStandardManagerState(this);

        /** @type {NARAStandardManagerCache} */
        this.cached = new NARAStandardManagerCache(this);

        /** @type {NARAStandardManagerAPI} */
        this.api = new NARAStandardManagerAPI(this);

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {NARAStandardManager['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {NARAStandardManager['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {NARAStandardManager['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {NARAStandardManager['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<NARAStandardManagerConstants, 'initialize'> & Omit<NARAStandardManagerState, 'initialize'> & Omit<NARAStandardManagerCache, 'initialize' | 'initStatusConditionals' | 'initOpenModalConditionals' | 'initNARAStandardTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     * @returns {NARAStandardManagerAPI['events'] & NARAStandardManagerAPI['methods']}
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.events,
            ...$context.api.methods,
            ...$context.api.getters,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link NARAStandardManager} composable.
 */
class NARAStandardManagerConstants {
    /**
     * Instantiate submodule.
     * @param {NARAStandardManager} context
     */
    constructor(context) {
        /** @type {NARAStandardManager} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ([
            'addNARAStandard',
            'editNARAStandard',
            'viewNARAStandard',
            'confirmDelete',
        ]);
        /** Default column definition. */
        this.defaultColDef = Object.freeze({
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            headerName: '',
            cellClass: 'leading-5 py-4 break-normal',
            flex: 1,
        });
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link NARAStandardManager} composable.
 */
class NARAStandardManagerState {
    /**
     * Instantiate submodule.
     * @param {NARAStandardManager} context
     */
    constructor(context) {
        /** @type {NARAStandardManager} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {V.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== OPEN MODALS ====
        /** @type {V.Ref<'addNARAStandard' | 'editNARAStandard' | 'viewNARAStandard' | 'confirmDelete' >} Only one modal can be open at a time. */
        this.openModal = ref(null);

        // ==== ACCOUNT INDEX ====
        /** @type {V.Ref<Map<Number, NARAStandardResource>>} */
        this.NARAStandardIndex = ref(new Map());

        // ==== ACCOUNT TARGETS ====
        /** @type {V.Ref<Pick<NARAStandardResource, 'id' | 'standard'>>} NARAStandard target. */
        this.NARAStandardToAdd = ref(null);

        /** @type {V.Ref<Pick<NARAStandardResource, 'id' | 'standard'>>} NARAStandard target. */
        this.NARAStandardToEdit = ref(null);

        /** @type {V.Ref<Pick<NARAStandardResource, 'id' | 'standard'>>} NARAStandard target. */
        this.NARAStandardToView = ref(null);

        /** @type {V.Ref<Pick<NARAStandardResource, 'id' | 'standard'>>} NARAStandard target. */
        this.NARAStandardToDelete = ref(null);

        // ==== AG GRID ====
        /** @type {V.Ref<Array<NARAStandardResource>>} */
        this.rowData = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.adminColDefs = ref([]);
        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.viewColDefs = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link NARAStandardManager} composable.
 */
class NARAStandardManagerCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {NARAStandardManager} context
     */
    constructor(context) {
        /** @type {NARAStandardManager} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
        // ==== CONDITIONALS (MODALS) ====
        this.initOpenModalConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.standards.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }

    /**
     * Initialize the open modal conditionals.
     */
    initOpenModalConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isAddNARAStandardModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'addNARAStandard';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditNARAStandardModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'editNARAStandard';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isViewNARAStandardModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'viewNARAStandard';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmDeleteModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmDelete';
        });
    }
}

class NARAStandardManagerAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {NARAStandardManager} context
     */
    constructor(context) {
        /** @type {NARAStandardManager} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const { state, store } = $api.context;

        /**
         * Get the account's current temperature unit.
         * @returns {Unit['Celsius'] | Unit['Fahrenheit'] | Unit['Unknown']}
         */
        const getTemperatureUnit = () => {
            const tempScale = store.state.accounts.account.tempScale;
            switch (tempScale) {
                case Temperature['Celcius (°C)']:
                    return Unit.Celsius;
                case Temperature['Fahrenheit (°F)']:
                    return Unit.Fahrenheit;
                default:
                    return Unit.Unknown;
            }
        };

        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @returns {String}
         */
        const formatTemperatureHeader = (label) =>
            `${label} ${getTemperatureUnit()}`;

        /**
         * Format header label with unit.
         * @param {String} label Header label to format.
         * @returns {String}
         */
        const formatRelativeHumidityHeader = (label) =>
            `${label} ${Unit.Percent}RH`;

        /**
         * Format the ISO string into just its date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateComponentFormat = (params) => {
            const value = params.value;
            if (!isNil(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatted = formatISO(date, {
                    format: 'extended',
                    representation: 'date',
                });
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Format the decimal value into a temperature string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useTemperatureFormat = (params) => {
            const value =
                isNil(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {{ unit: Pick<Unit, 'Celsius' | 'Fahrenheit' | 'Unknown'>[keyof Pick<Unit, 'Celsius' | 'Fahrenheit' | 'Unknown'>] } & Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} */
            const options = { unit: getTemperatureUnit() };
            const formatted = formatTemperature({
                value: Math.round(value),
                options,
            });
            return formatted === '' ? '--' : formatted;
        };

        /**
         * Format the decimal value into a percent string.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const usePercentFormat = (params) => {
            const value =
                isNil(params?.value) || params?.value === ''
                    ? NaN
                    : Number(params?.value);
            /** @type {Pick<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>} */
            const options = {};
            const formatted = formatPercent({ value, options });
            return formatted === '' ? '--' : formatted;
        };

        /**
         * Get the default column definitions.
         * @returns {Readonly<AgGrid.ColumnDef>}
         */
        const getDefaultColDef = () => this.context.constants.defaultColDef;

        /**
         * Get keyed column definitions.
         */
        const getColumnSchema = () => {
            return {
                /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                adminIcons: {
                    headerName: '',
                    field: 'id',
                    cellRendererFramework: NARAStandardTableIcons,
                    filter: false,
                    minWidth: 80,
                    maxWidth: 120,
                    cellRendererParams: {
                        /**
                         * Handle view of a NARAStandard.
                         * @param {Object} event
                         * @param {Number} index NARAStandard index.
                         */
                        handleView: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const NARAStandard =
                                state.NARAStandardIndex.value.get(id);
                            $api.events.onClick.viewNARAStandard(NARAStandard);
                        },
                        /**
                         * Handle editing of a NARAStandard.
                         * @param {Object} event
                         * @param {Number} index NARAStandard index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const NARAStandard =
                                state.NARAStandardIndex.value.get(id);
                            $api.events.onClick.editNARAStandard(NARAStandard);
                        },
                        /**
                         * Handle deletion of a NARAStandard.
                         * @param {Object} event
                         * @param {Number} index NARAStandard index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const NARAStandard =
                                state.NARAStandardIndex.value.get(id);
                            $api.events.onClick.deleteNARAStandard(
                                NARAStandard
                            );
                        },
                    },
                },
                /** @type {AgGrid.ColumnDef} */
                standard: {
                    headerName: 'Standard',
                    field: 'standard',
                    sort: 'asc',
                    comparator: (valueA, valueB) => {
                        const valueALower = valueA.toLowerCase().trim();
                        const valueBLower = valueB.toLowerCase().trim();
                        return valueALower.localeCompare(valueBLower, 'en');
                    },
                    minWidth: 100,
                    flex: 5,
                    wrapText: true,
                    autoHeight: true,
                },
                /** @type {AgGrid.ColumnDef} */
                description: {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    width: 75,
                    flex: 10,
                },
                /** @type {AgGrid.ColumnDef} */
                minTemp: {
                    headerName: formatTemperatureHeader('Min Temp.'),
                    field: 'min_temp',
                    minWidth: 80,
                    headerClass: 'text-center',
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    valueFormatter: useTemperatureFormat,
                },
                /** @type {AgGrid.ColumnDef} */
                maxTemp: {
                    headerName: formatTemperatureHeader('Max Temp.'),
                    field: 'max_temp',
                    minWidth: 80,
                    headerClass: 'text-center',
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    valueFormatter: useTemperatureFormat,
                },
                /** @type {AgGrid.ColumnDef} */
                minRh: {
                    headerName: formatRelativeHumidityHeader('Min'),
                    field: 'min_rh',
                    minWidth: 80,
                    headerClass: 'text-center',
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    valueFormatter: usePercentFormat,
                },
                /** @type {AgGrid.ColumnDef} */
                maxRh: {
                    headerName: formatRelativeHumidityHeader('Max'),
                    field: 'max_rh',
                    minWidth: 80,
                    headerClass: 'text-center',
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    valueFormatter: usePercentFormat,
                },
            };
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getAdminColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.adminIcons,
                schema.standard,
                schema.minTemp,
                schema.maxTemp,
                schema.minRh,
                schema.maxRh,
                schema.description,
            ];
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getViewColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.standard,
                schema.minTemp,
                schema.maxTemp,
                schema.minRh,
                schema.maxRh,
                schema.description,
            ];
        };

        /**
         * Create NARAStandard index from array,
         * @param {NARAStandardResource[]} standards
         */
        const getNARAStandardsAsIndex = (standards) => {
            /** @type {[ id: Number, NARAStandard: NARAStandardResource ][]} */
            const entries = standards.map((a) => {
                /** @type {[ id: Number, NARAStandard: NARAStandardResource ]} */
                const entry = [a.id, { ...a }];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Map
         * @param {NARAStandardResource[]} standards
         * @returns
         */
        const getNARAStandardsAsRowData = (standards) => {
            return standards.map((NARAStandard) =>
                getNARAStandardAsRecord(NARAStandard)
            );
        };

        /**
         * Copy NARAStandard and modify it for the row data.
         * @param {NARAStandardResource} NARAStandard
         */
        const getNARAStandardAsRecord = (NARAStandard) => ({
            ...NARAStandard,
        });

        /**
         * Copy NARAStandard from index as a selected NARAStandard target.
         * @param {NARAStandardResource['id']} id
         */
        const getNARAStandardAsTarget = (id) => {
            const source = state.NARAStandardIndex.value.get(id);
            return {
                ...pick(
                    source,
                    'id',
                    'standard',
                    'description',
                    'min_temp',
                    'max_temp',
                    'min_rh',
                    'max_rh'
                ),
            };
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            useDateComponentFormat,
            useTemperatureFormat,
            usePercentFormat,
            getDefaultColDef,
            getColumnSchema,
            getAdminColumnDefs,
            getViewColumnDefs,
            getNARAStandardsAsIndex,
            getNARAStandardsAsRowData,
            getNARAStandardAsRecord,
            getNARAStandardAsTarget,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set the open modal.
         * @param {'addNARAStandard' | 'editNARAStandard' | 'viewNARAStandard' | 'confirmDelete' } id
         */
        const setOpenModal = (id = null) => {
            state.openModal.value = id ?? null;
        };

        /**
         * Set NARAStandard index instance.
         * @param {Map<Number, NARAStandardResource>} index
         */
        const setNARAStandardIndex = (index) => {
            state.NARAStandardIndex.value = new Map(index.entries());
        };

        /**
         * Set NARAStandard target for new NARAStandard.
         * @param {Pick<NARAStandardResource, 'id' | 'standard'> & Partial<NARAStandardResource>} target
         */
        const setAddNARAStandardTarget = (target) => {
            state.NARAStandardToAdd.value = target;
        };

        /**
         * Set NARAStandard target for the corresponding id.
         * @param {NARAStandardResource['id']} id
         */
        const setEditNARAStandardTarget = (id) => {
            const { getNARAStandardAsTarget } = $api.getters;
            const target = id ? getNARAStandardAsTarget(id) : null;
            state.NARAStandardToEdit.value = target;
        };

        /**
         * Set NARAStandard target for the corresponding id.
         * @param {NARAStandardResource['id']} id
         */
        const setViewNARAStandardTarget = (id) => {
            const { getNARAStandardAsTarget } = $api.getters;
            const target = id ? getNARAStandardAsTarget(id) : null;
            state.NARAStandardToView.value = target;
        };

        /**
         * Set NARAStandard target for the corresponding id.
         * @param {NARAStandardResource['id']} id
         */
        const setDeleteNARAStandardTarget = (id) => {
            const { getNARAStandardAsTarget } = $api.getters;
            const target = id ? getNARAStandardAsTarget(id) : null;
            state.NARAStandardToDelete.value = target;
        };

        /**
         * Set the row data.
         * @param {NARAStandardResource[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setLoading,
            setOpenModal,
            setNARAStandardIndex,
            setRowData,
            get setNARAStandardTarget() {
                return {
                    toAdd: setAddNARAStandardTarget,
                    toEdit: setEditNARAStandardTarget,
                    toView: setViewNARAStandardTarget,
                    toDelete: setDeleteNARAStandardTarget,
                };
            },
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When standards index is loaded/refreshed,
         * update the row data
         * @param {NARAStandardResource[]} standards
         */
        const onUpdateNARAStandards = (standards) => {
            const { getNARAStandardsAsIndex, getNARAStandardsAsRowData } =
                $api.getters;
            const { setNARAStandardIndex, setRowData } = $api.setters;
            const NARAStandardIndex = getNARAStandardsAsIndex(standards);
            const NARAStandardData = getNARAStandardsAsRowData(standards);
            setNARAStandardIndex(NARAStandardIndex);
            setRowData(NARAStandardData);
        };

        /**
         * On click add NARAStandard.
         */
        const onClickAddNARAStandard = () => {
            const { open } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            setNARAStandardTarget.toAdd({
                id: null,
                standard: '',
                description: '',
                min_temp: -Infinity,
                max_temp: Infinity,
                min_rh: -Infinity,
                max_rh: Infinity,
            });
            open.addNARAStandardModal();
        };

        /**
         * On click edit NARAStandard.
         * @param {NARAStandardResource} target
         */
        const onClickEditNARAStandard = (target) => {
            const { open } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            setNARAStandardTarget.toEdit(target.id);
            open.editNARAStandardModal();
        };

        /**
         * On click view NARAStandard.
         * @param {NARAStandardResource} target
         */
        const onClickViewNARAStandard = (target) => {
            const { open } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            setNARAStandardTarget.toView(target.id);
            open.viewNARAStandardModal();
        };

        /**
         * On click delete NARAStandard.
         * @param {NARAStandardResource} target
         */
        const onClickDeleteNARAStandard = (target) => {
            const { open } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            setNARAStandardTarget.toDelete(target.id);
            open.confirmDeleteModal();
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelAddNARAStandard = () => {
            const { close } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            close.addNARAStandardModal();
            setNARAStandardTarget.toAdd(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelEditNARAStandard = () => {
            const { close } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            close.editNARAStandardModal();
            setNARAStandardTarget.toEdit(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelViewNARAStandard = () => {
            const { close } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            close.viewNARAStandardsModal();
            setNARAStandardTarget.toView(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelDeleteNARAStandard = () => {
            const { close } = $api.methods;
            const { setNARAStandardTarget } = $api.setters;
            close.confirmDeleteModal();
            setNARAStandardTarget.toDelete(null);
        };

        /**
         * Submit the action.
         * @param {Pick<NARAStandardResource, 'id' | 'standard' | 'description' | 'max_rh' | 'min_rh' | 'max_temp' | 'min_temp'>} NARAStandard
         */
        const onSubmitAddNARAStandard = async (NARAStandard) => {
            const { close, refreshNARAStandards } = $api.methods;
            const { setLoading, setNARAStandardTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.addNARAStandardModal();
            try {
                console.time(`[NARAStandard::add]`);
                setLoading('loading');
                // ==== EDIT ACCOUNT ====

                const payload = {
                    standard: NARAStandard.standard,
                    description: NARAStandard.description,
                    min_temp: NARAStandard.min_temp,
                    max_temp: NARAStandard.max_temp,
                    min_rh: NARAStandard.min_rh,
                    max_rh: NARAStandard.max_rh,
                };
                await createNARAStandard(payload);
                pushAlert(
                    createAlert({
                        id: `add-success`,
                        type: 'success',
                        title: `NARAStandard Success`,
                        messages: [`NARAStandard successfully created.`],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshNARAStandards(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `add-error`,
                        type: 'error',
                        title: `Add NARAStandard Error`,
                        messages: [
                            'Error adding the NARAStandard, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[NARAStandard::add]`);
                setNARAStandardTarget.toAdd(null);
            }
        };

        /**
         * Submit the action.
         * @param {Pick<NARAStandardResource, 'id' | 'standard' | 'description' | 'max_rh' | 'min_rh' | 'max_temp' | 'min_temp'>} NARAStandard
         */
        const onSubmitEditNARAStandard = async (NARAStandard) => {
            const { close, refreshNARAStandards } = $api.methods;
            const { setLoading, setNARAStandardTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.editNARAStandardModal();
            try {
                console.time(`[NARAStandard::edit]`);
                setLoading('loading');
                // ==== EDIT ACCOUNT ====
                const request = state.NARAStandardToEdit.value;
                const payload = {
                    standard: NARAStandard.standard,
                    description: NARAStandard.description,
                    min_temp: NARAStandard.min_temp,
                    max_temp: NARAStandard.max_temp,
                    min_rh: NARAStandard.min_rh,
                    max_rh: NARAStandard.max_rh,
                };
                await updateNARAStandardById(request, payload);
                pushAlert(
                    createAlert({
                        id: `edit-success`,
                        type: 'success',
                        title: `Edit NARAStandard Success`,
                        messages: ['NARAStandard successfully updated.'],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshNARAStandards(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `edit-error`,
                        type: 'error',
                        title: `Edit NARAStandard Error`,
                        messages: [
                            'Error editing the NARAStandard, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[NARAStandard::edit]`);
                setNARAStandardTarget.toEdit(null);
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitDeleteNARAStandard = async () => {
            const { close, refreshNARAStandards } = $api.methods;
            const { setLoading, setNARAStandardTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.confirmDeleteModal();
            try {
                console.time(`[NARAStandard::delete]`);
                setLoading('loading');
                // ==== DELETE ACCOUNT ====
                const request = state.NARAStandardToDelete.value;
                await deleteNARAStandardById(request);
                pushAlert(
                    createAlert({
                        id: `delete-success`,
                        type: 'success',
                        title: `Delete NARAStandard Success`,
                        messages: ['NARAStandard successfully deleted.'],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshNARAStandards(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `delete-error`,
                        type: 'error',
                        title: `Delete NARAStandard Error`,
                        messages: [
                            'Error deleting NARAStandard, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[NARAStandard::delete]`);
                setNARAStandardTarget.toDelete(null);
            }
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getAdminColumnDefs } = $api.getters;
            state.adminColDefs.value = [...getAdminColumnDefs()];
            const { getViewColumnDefs } = $api.getters;
            state.viewColDefs.value = [...getViewColumnDefs()];
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateNARAStandards,
            get onClick() {
                return {
                    addNARAStandard: onClickAddNARAStandard,
                    editNARAStandard: onClickEditNARAStandard,
                    viewNARAStandard: onClickViewNARAStandard,
                    deleteNARAStandard: onClickDeleteNARAStandard,
                };
            },
            get onCancel() {
                return {
                    addNARAStandard: onCancelAddNARAStandard,
                    editNARAStandard: onCancelEditNARAStandard,
                    viewNARAStandard: onCancelViewNARAStandard,
                    deleteNARAStandard: onCancelDeleteNARAStandard,
                };
            },
            get onSubmit() {
                return {
                    addNARAStandard: onSubmitAddNARAStandard,
                    editNARAStandard: onSubmitEditNARAStandard,
                    deleteNARAStandard: onSubmitDeleteNARAStandard,
                };
            },
        };
    }

    initMethods() {
        const $api = this;
        const { state, standards } = $api.context;

        /**
         * Update the row data after requesting standards from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshNARAStandards = async (forceReload = false) => {
            const { setLoading } = $api.setters;
            try {
                console.time(
                    `[standards::index] - Refreshing NARAStandard index:`
                );
                setLoading('loading');
                // ==== REFRESH ====
                const NARAStandardList =
                    await standards.refreshNARAStandardsIndex(forceReload);
                $api.events.onUpdateNARAStandards(NARAStandardList ?? []);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            } finally {
                console.timeEnd(
                    `[standards::index] - Refreshing NARAStandard index:`
                );
            }
        };

        /** Open modal. */
        const openAddNARAStandardModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('addNARAStandard');
        };

        /** Open modal. */
        const openEditNARAStandardModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('editNARAStandard');
        };

        /** Open modal. */
        const openViewNARAStandardModal = async () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('viewNARAStandard');

            // Refresh the NARAStandards on initial page load.
            await refreshNARAStandards(false);
        };

        /** Open modal. */
        const openConfirmDeleteModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmDelete');
        };

        /**
         * Close modal if it matches.
         * @param {'addNARAStandard' | 'editNARAStandard' | 'viewNARAStandard' | 'confirmDelete'} id
         */
        const closeModalIfOpen = (id) => {
            if (state.openModal.value === id) {
                // Close modal if it matches.
                $api.setters.setOpenModal(null);
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            refreshNARAStandards,
            get open() {
                return {
                    addNARAStandardModal: openAddNARAStandardModal,
                    editNARAStandardModal: openEditNARAStandardModal,
                    viewNARAStandardModal: openViewNARAStandardModal,
                    confirmDeleteModal: openConfirmDeleteModal,
                };
            },
            get close() {
                return {
                    addNARAStandardModal: () =>
                        closeModalIfOpen('addNARAStandard'),
                    editNARAStandardModal: () =>
                        closeModalIfOpen('editNARAStandard'),
                    viewNARAStandardModal: () =>
                        closeModalIfOpen('viewNARAStandard'),
                    confirmDeleteModal: () => closeModalIfOpen('confirmDelete'),
                };
            },
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 */
export const useNARAStandardManager = () => {
    const context = new NARAStandardManager();
    return context.initialize();
};

// <!-- DEFAULT -->
export default useNARAStandardManager;
