// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';

// <!-- TYPES -->

/**
 * Using a cache (or with a new instance), handle index operations.
 * @param {ECNBCache} [vuexCache]
 */
export const useNARAStandardIndex = (vuexCache) => {
    // STORE

    /** @type {ECNBCache} */
    const cache = vuexCache ?? useECNBCache();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetching = ref(false);

    // COMPUTED PROPERTIES

    /** @type {V.ComputedRef<import('@/api/standards').NARAStandardResource[]>} */
    const NARAStandards = computed(() => {
        // ts-ignore
        const NARAStandardsCache = cache.standards
            ? cache.standards.value
            : null;
        const isEmpty = NARAStandardsCache ? NARAStandardsCache.is.empty : true;
        return isEmpty ? [] : NARAStandardsCache.all.all();
    });

    // METHODS

    /**
     * Refetch the cached index for the page.
     * @param {Boolean} forceReload
     */
    const refreshNARAStandardsIndex = async (forceReload = false) => {
        try {
            isFetching.value = true;
            const ignoreCache = forceReload === true;
            await cache.fetch.standards({ ignoreCache });
            return NARAStandards.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetching.value = false;
        }
    };

    return {
        refreshNARAStandardsIndex,
        isFetching,
        cache,
        NARAStandards,
    };
};
